import React, { useEffect } from 'react';

interface MetaTagsProps {
  language: 'fr' | 'en'; // Accepts either 'fr' or 'en' for language selection
  url: string; // Base URL for the page (used in og:url)
  imageUrl: string; // Path to the image used in og:image
  faviconUrl?: string; // Optional URL for the favicon
}

const MetaTag: React.FC<MetaTagsProps> = ({ language, url, imageUrl, faviconUrl = 'favicon.ico' }) => {
  const phoneNumber = '+41 78 212 40 27';
  const address = 'Rue de Villereuse 1, 1207 Genève, Suisse';
  const postalCode = '1207';

  const seoKeywords = ["Cordonnerie","Cordonnerie multiservices","multiservices Villereuse",
"Cordonnerie Villereuse","cordonrie","cordonrie villereuse","Cordonnerie geneve","Cordonnerie geneva",
"Cordonnerie multiservices geneve","multiservices geneve","Cordonnerie multiservices Villereuse geneve",
"Cordonnerie multiservices Villereuse geneva","multiservices Villereuse geneva","geneve","geneva",
"Cordonnerie geneva multiservices","Cordonnerie geneve multiservices","Cordonnerie geneva multiservices suisse",
"Cordonnerie multiservices suisse","Cordonnerie multiservices suisse geneva","Cordonnerie multiservices suisse geneve",
"Cordonnerie suisse ","suisse ","multiservices suisse ",
    "Cordonnerie multiservices Villereuse", "Cordonnerie multiservices","Cordonnerie Villereuse",
    "Cordonnerie multiservices Villereuse.com","https://cordonnerie-multiservices-villereuse.com/",
    "cordonnerie-multiservices-villereuse.com","Réparation de chaussures Villereuse","Service de cordonnerie Villereuse",
    "Cordonnerie à Villereuse","Cordonnerie pour chaussures Villereuse","Cordonnerie et réparation Villereuse","Réparation de clés Villereuse","cordonnerie",
    "Duplication de clés Villereuse","Cordonnerie professionnelle Villereuse",
    "Cordonnerie multiservices Genève", "Cordonnerie multiservices Suisse", "Cordonnerie Genève", 
    "Réparation de chaussures Genève", "Service de cordonnerie Genève", "Cordonnerie à Genève", 
    "Cordonnerie pour chaussures Genève", "Cordonnerie et réparation Genève", "Réparation de clés Genève", 
    "Service de maroquinerie Genève", "Duplication de clés Genève", "Cordonnerie professionnelle Genève", 
    "Cordonnerie multiservices à Genève", "Réparation de sacs Genève", "Services de cordonnerie Genève", 
    "Atelier de cordonnerie Genève", "Cordonnerie spécialisée Genève", "Cordonnerie rapide Genève", 
    "Cordonnerie de qualité Genève", "Réparation de chaussures à Genève", "Réparation de sacs en cuir Genève", 
    "Cordonnerie pour maroquinerie Genève", "Cordonnerie pour chaussures Genève", "Services de réparation de clés Genève", 
    "Réparation d’accessoires en cuir Genève", "Cordonnerie rapide Genève", "Cordonnerie clé en main Genève", 
    "Duplication de clés à Genève", "Réparation express de chaussures Genève", "Cordonnerie spécialisée en chaussures Genève", 
    "Cordonnerie pour entreprises Genève", "Cordonnerie pour particuliers Genève", "Cordonnerie et services multiples Genève", 
    "Réparation de porte-clés Genève", "Maroquinerie à Genève", "Réparation de sacs à main Genève", 
    "Cordonnerie de proximité Genève", "Réparation express à Genève", "Réparation de chaussures et sacs Genève", 
    "Cordonnerie à domicile Genève", "Réparation de chaussures cuir Genève", "Réparation de sacs en cuir à Genève", 
    "Cordonnerie multiservices à domicile Genève", "Cordonnerie traditionnelle Genève", "Reproduction de clés Genève", 
    "Cordonnerie de luxe Genève", "Services de cordonnerie pour professionnels Genève", "Services de cordonnerie pour particuliers Genève", 
    "Artisan cordonnier Genève", "Cordonnerie à prix compétitifs Genève", "Réparation de cuir Genève", 
    "Services de réparation à Genève", "Cordonnerie en ligne Genève", "Services de qualité en cordonnerie Genève", 
    "Cordonnerie pour réparation de sacs Genève", "Entretien de chaussures Genève", "Réparation de sacs et portefeuilles Genève", 
    "Réparation de clés et serrures Genève", "Services de nettoyage de chaussures Genève", "Cordonnerie à Genève pour tous les types de chaussures", 
    "Restauration de sacs en cuir Genève", "Cordonnerie rapide et fiable Genève", "Réparation de bottes Genève", 
    "Cordonnerie pour chaussures en cuir Genève", "Cordonnerie pour sacs en cuir Genève", "Services de cordonnerie à Genève", 
    "Réparation de sacs à main de marque Genève", "Restauration de maroquinerie Genève", "Cordonnerie pour chaussures usées Genève", 
    "Réparation de talons de chaussures Genève", "Cordonnerie haute qualité Genève", "Cordonnerie sur-mesure Genève", 
    "Réparation de sacs et chaussures haut de gamme Genève", "Entretien de sacs Genève", "Services de cordonnerie pour maroquinerie Genève", 
    "Cordonnerie en urgence Genève", "Réparation rapide de sacs Genève", "Cordonnerie clé et serrurerie Genève", 
    "Cordonnerie pour réparation de ceintures Genève", "Services de nettoyage et réparation Genève", "Cordonnerie à Genève pour chaussures en daim", 
    "Service de réparation de sacs à main Genève", "Artisan cordonnier Genève", "Réparation de bagages Genève", 
    "Cordonnerie à Genève pour réparation de semelles", "Cordonnerie spécialisée pour femmes Genève", "Services de réparation de sacs en cuir Genève", 
    "Réparation de chaussures anciennes Genève", "Cordonnerie multilangue Genève", "Services de cordonnerie pour entreprises Genève", 
    "Cordonnerie pour réparations de tongs Genève", "Cordonnerie rapide pour chaussures Genève", "Cordonnerie et nettoyage de cuir Genève", 
    "Réparation de sacs en tissu Genève", "Cordonnerie pour réparation de sacs en toile Genève", "Services de réfection de sacs Genève", 
    "Services de retouche de chaussures Genève", "Cordonnerie pour réparation de bottines Genève", "Entretien de maroquinerie Genève", 
    "Cordonnerie Genève pour vos chaussures","Cordonnerie multiservices Villereuse",
"Cordonnerie multiservices",
"Cordonnerie Villereuse",
"Cordonnerie multiservices Villereuse.com",
"https://cordonnerie-multiservices-villereuse.com/",
"cordonnerie-multiservices-villereuse.com",
"Shoe repair Villereuse",
"Shoe repair service Villereuse",
"Cordonnerie in Villereuse",
"Cordonnerie for shoes Villereuse",
"Cordonnerie and repair Villereuse",
"Key repair Villereuse",
"Cordonnerie",
"Key duplication Villereuse",
"Professional Cordonnerie Villereuse",
"Cordonnerie multiservices Geneva",
"Cordonnerie multiservices Switzerland",
"Cordonnerie Geneva",
"Shoe repair Geneva",
"Cordonnerie service Geneva",
"Cordonnerie in Geneva",
"Cordonnerie for shoes Geneva",
"Cordonnerie and repair Geneva",
"Key repair Geneva",
"Leather goods service Geneva",
"Key duplication Geneva",
"Professional Cordonnerie Geneva",
"Cordonnerie multiservices in Geneva",
"Bag repair Geneva",
"Cordonnerie services Geneva",
"Cordonnerie workshop Geneva",
"Specialized Cordonnerie Geneva",
"Fast Cordonnerie Geneva",
"Quality Cordonnerie Geneva",
"Shoe repair in Geneva",
"Leather bag repair Geneva",
"Cordonnerie for leather goods Geneva",
"Cordonnerie for shoes Geneva",
"Key repair services Geneva",
"Leather accessory repair Geneva",
"Fast Cordonnerie Geneva",
"Turnkey Cordonnerie Geneva",
"Key duplication in Geneva",
"Express shoe repair Geneva",
"Cordonnerie specialized in shoes Geneva",
"Cordonnerie for businesses Geneva",
"Cordonnerie for individuals Geneva",
"Cordonnerie and multiple services Geneva",
"Keychain repair Geneva",
"Leather goods in Geneva",
"Handbag repair Geneva",
"Local Cordonnerie Geneva",
"Express repair in Geneva",
"Shoe and bag repair Geneva",
"Home Cordonnerie Geneva",
"Leather shoe repair Geneva",
"Leather bag repair in Geneva",
"Cordonnerie multiservices at home Geneva",
"Traditional Cordonnerie Geneva",
"Key reproduction Geneva",
"Luxury Cordonnerie Geneva",
"Cordonnerie services for professionals Geneva",
"Cordonnerie services for individuals Geneva",
"Artisan Cordonnerie Geneva",
"Affordable Cordonnerie Geneva",
"Leather repair Geneva",
"Repair services in Geneva",
"Online Cordonnerie Geneva",
"Quality Cordonnerie services Geneva",
"Cordonnerie for bag repairs Geneva",
"Shoe maintenance Geneva",
"Bag and wallet repairs Geneva",
"Key and lock repairs Geneva",
"Shoe cleaning services Geneva",
"Cordonnerie in Geneva for all types of shoes",
"Leather bag restoration Geneva",
"Fast and reliable Cordonnerie Geneva",
"Boot repair Geneva",
"Cordonnerie for leather shoes Geneva",
"Cordonnerie for leather bags Geneva",
"Cordonnerie services in Geneva",
"Brand handbag repairs Geneva",
"Leather goods restoration Geneva",
"Cordonnerie for worn shoes Geneva",
"Heel repair for shoes Geneva",
"High-quality Cordonnerie Geneva",
"Custom-made Cordonnerie Geneva",
"High-end shoe and bag repair Geneva",
"Leather bag maintenance Geneva",
"Cordonnerie services for leather goods Geneva",
"Emergency Cordonnerie Geneva",
"Fast bag repair Geneva",
"Cordonnerie and locksmith Geneva",
"Cordonnerie for belt repairs Geneva",
"Shoe cleaning and repair services Geneva",
"Cordonnerie in Geneva for suede shoes",
"Handbag repair services Geneva",
"Artisan Cordonnerie Geneva",
"Luggage repair Geneva",
"Cordonnerie in Geneva for sole repairs"
  ].join(', ');

  // Utility function to update or create meta tags
  const updateOrCreateMetaTag = (nameOrProperty: string, content: string, isProperty = false) => {
    const attribute = isProperty ? 'property' : 'name';
    let tag = document.querySelector(`meta[${attribute}="${nameOrProperty}"]`);
    if (tag) {
      tag.setAttribute('content', content);
    } else {
      tag = document.createElement('meta');
      tag.setAttribute(attribute, nameOrProperty);
      tag.setAttribute('content', content);
      document.head.appendChild(tag);
    }
  };

  const setFavicon = (url: string) => {
    let link: HTMLLinkElement | null = document.querySelector("link[rel='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = url;
  };

  useEffect(() => {
    setFavicon(faviconUrl); // Set the favicon

    if (language === 'fr') {
      document.title = "Cordonnerie Multiservice Villereuse - Réparation de chaussures et services divers";

      // General meta description
      updateOrCreateMetaTag(
        'description',
        `Cordonnerie Multiservice Villereuse vous offre des services de réparation de chaussures, duplication de clés, gravure personnalisée, vente de produits d’entretien pour chaussures. Situé à ${address} (${postalCode}). Contactez-nous au ${phoneNumber}.`
      );

      // Meta Keywords
      updateOrCreateMetaTag('keywords', seoKeywords); 

      // Open Graph meta tags
      updateOrCreateMetaTag('og:title', 'Cordonnerie Multiservice Villereuse - Réparation de chaussures et services divers', true);
      updateOrCreateMetaTag(
        'og:description',
        `Nous offrons des services de réparation de chaussures, gravure personnalisée, duplication de clés, entretien de montres et vente de produits de soin pour chaussures. Adresse: ${address}, Code Postal: ${postalCode}, Téléphone: ${phoneNumber}.`,
        true
      );
      updateOrCreateMetaTag('og:image', imageUrl, true);
      updateOrCreateMetaTag('og:type', 'website', true);
      updateOrCreateMetaTag('og:url', url, true);

      // Twitter Card meta tags
      updateOrCreateMetaTag('twitter:card', 'summary_large_image');
      updateOrCreateMetaTag('twitter:title', 'Cordonnerie Multiservice - Réparation de chaussures et services divers');
      updateOrCreateMetaTag(
        'twitter:description',
        `Cordonnerie Multiservice Villereuse vous offre des services de réparation de chaussures et bien plus encore. Adresse: ${address}, Téléphone: ${phoneNumber}.`
      );
      updateOrCreateMetaTag('twitter:image', imageUrl);
    } else {
      document.title = "Cordonnerie Multiservice Villereuse - Shoe Repair and More Services";

      // General meta description
      updateOrCreateMetaTag(
        'description',
        `Cordonnerie Multiservice Villereuse offers shoe repair services, key duplication, engraving, watch maintenance, and shoe care products. Located at ${address}, ${postalCode}. Contact us at ${phoneNumber}.`
      );

      // Meta Keywords
      updateOrCreateMetaTag('keywords', seoKeywords);

      // Open Graph meta tags
      updateOrCreateMetaTag('og:title', 'Cordonnerie Multiservice Villereuse - Shoe Repair and More Services', true);
      updateOrCreateMetaTag(
        'og:description',
        `We offer shoe repair, engraving, key duplication, personalized stamps, watch services, and high-quality shoe care products. Address: ${address}, Postal Code: ${postalCode}, Phone: ${phoneNumber}.`,
        true
      );
      updateOrCreateMetaTag('og:image', imageUrl, true);
      updateOrCreateMetaTag('og:type', 'website', true);
      updateOrCreateMetaTag('og:url', url, true);

      // Twitter Card meta tags
      updateOrCreateMetaTag('twitter:card', 'summary_large_image');
      updateOrCreateMetaTag('twitter:title', 'Cordonnerie Multiservice - Shoe Repair and More Services');
      updateOrCreateMetaTag(
        'twitter:description',
        `Cordonnerie Multiservice Villereuse offers various shoe repair services, key duplication, and more. Address: ${address}, Phone: ${phoneNumber}.`
      );
      updateOrCreateMetaTag('twitter:image', imageUrl);
    }
  }, [language, url, imageUrl, faviconUrl]);

  return null; // This component does not render anything itself
};

export default MetaTag;
