import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Contact from './Contact';
import Aceuil from './Aceuil';
import Admin from './Admin';
import Contacter from './Contacter';


const Appp: React.FC = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Aceuil  />} />
          <Route path="/en/Home" element={<Home />} />
       
          <Route path="/en/Contact" element={<Contact />} />
    
          <Route path="/fr/Acceuil" element={<Aceuil />} />
          <Route path="/fr/Contact" element={<Contacter />} />
    
        </Routes>
      </div>
    </Router>
  );
};

export default Appp;
