import React, { useState } from 'react';
import { useAuth } from '../Components/AuthContext';
import Navbar from '../Components/Navbar';
import ScrollTo from '../Components/ScrollTo';
import ScrollToTop from '../Components/ScrollToTop';
import Footer from '../Components/Footer';
import GetData from '../Components/GetData';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Admin: React.FC = () => {
  const { isAuthenticated, login } = useAuth();
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Declare navigate

  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const correctPassword = 'Lz8#Yt9!Fv4$Nm2%Rx6&Qc7@Ws3*Gd5^Bp1#Kj8@Hv9&Mp0^Xz4';

    if (password === correctPassword) {
      login();
      navigate('/Admin'); // Redirect to Admin page after successful login
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  // Redirect to the login form if not authenticated
  if (!isAuthenticated) {
    return (
      <form onSubmit={handlePasswordSubmit} style={{ textAlign: 'center', marginTop: '200px', marginBottom: '226px' }}>
        <h2>Enter Admin Password</h2>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          style={{ padding: '10px', fontSize: '16px', margin: '10px 0' }}
        />
        <button type="submit" style={{ padding: '10px 20px', fontSize: '16px' }}>
          Submit
        </button>
      </form>
    );
  }

  return (
    <div>
      <Navbar />
      <ScrollTo />
      <div className="get">
        <GetData />
      </div>
      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default Admin;
