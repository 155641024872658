import React, { useState } from 'react';
import axios from 'axios';

// Define the structure of the response data
interface GitHubFileResponse {
  content: string;
  sha: string;
}

const FormComponent: React.FC = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const repoOwner = 'othmaneelwahidi';
  const repoName = 'Cordonnerie';
  const filePath = 'Cordonnerie.json';
  const branch = 'main';
  const githubToken = process.env.REACT_APP_GITHUB_TOKEN;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const newEntry = { fullName, email, phone, description };

    try {
      // Fetch the current file content from GitHub
      const currentFileResponse = await axios.get<GitHubFileResponse>(
        `https://api.github.com/repos/${repoOwner}/${repoName}/contents/${filePath}?ref=${branch}`,
        {
          headers: { Authorization: `Bearer ${githubToken}` },
        }
      );

      console.log('GitHub File Response:', currentFileResponse.data); // Log the full response for debugging

      // Ensure that content is available
      if (!currentFileResponse.data || !currentFileResponse.data.content) {
        throw new Error('No content found in GitHub file response');
      }

      // Decode and parse the content from GitHub
      const currentFileContent = atob(currentFileResponse.data.content);
      let parsedContent: any[] = [];

      try {
        parsedContent = JSON.parse(currentFileContent);
      } catch (error) {
        console.error('Error parsing GitHub file content:', error);
        throw new Error('Error parsing GitHub file content');
      }

      // Handle the case when the file content is empty (initial state is [])
      const updatedContent = Array.isArray(parsedContent)
        ? [...parsedContent, newEntry] // Add new entry if the file already has data
        : [newEntry]; // Create a new array if the file is empty

      const updatedContentBase64 = btoa(JSON.stringify(updatedContent));

      // Log the updated content before sending the PUT request
      console.log('Updated Content:', updatedContent);
      console.log('Updated Content Base64:', updatedContentBase64);

      // Update the file in GitHub with the new content
      const updateResponse = await axios.put(
        `https://api.github.com/repos/${repoOwner}/${repoName}/contents/${filePath}`,
        {
          message: 'Update form submission',
          content: updatedContentBase64,
          branch,
          sha: currentFileResponse.data.sha, // SHA from the current file to ensure we are updating the correct file
        },
        {
          headers: { Authorization: `Bearer ${githubToken}` },
        }
      );

      console.log('Update response:', updateResponse.data); // Log update response for debugging

      setMessage('Form submitted successfully!');
      setFullName('');
      setEmail('');
      setPhone('');
      setDescription('');
    } catch (error: any) {
      console.error('Error posting form data to GitHub:', error);

      // Log the error to understand why the submission failed
      if (error.response) {
        console.error('Error Response:', error.response.data);
        setMessage(`Erreur lors de la soumission du formulaire: ${error.response.data.message}`);
      } else {
        setMessage(`Erreur lors de la soumission du formulaire: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="H">
        If you have any questions about our products or services, please feel free to reach out by using the form below. We’ll get back to you as soon as possible.
      </h2>
      <div className="form-container">
        <h2>Submit Your Information</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group-input">
            <label>Full Name</label>
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </div>
          <div className="form-group-input">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group-input">
            <label>Phone Number</label>
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="form-group-input">
            <label>Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={loading} className="to">
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default FormComponent;
